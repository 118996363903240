<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增商品</span>
      <div class="row">
        <div class="col-md-12">
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>分类</b></label><br/>
                <el-cascader :props="{label:'NAME',value:'ID',children:'CHILDREN'}" @change="getStock"
                             v-model="cat.val" :options="cat.ls" placeholder="请选择分类"/>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>物品</b></label><br/>
                <el-select v-model="model.ST_ID" placeholder="请选择物品" @change="stockChange" filterable>
                  <el-option
                      v-for="item in ls_stock"
                      :key="item.ID"
                      :label="item.NAME"
                      :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>库存量</b></label>
                <div>
                  <el-input v-model="stock.BALANCE" readonly style="width: 60%;">
                    <template slot="prepend" ><i class="el-icon-coin"/></template>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>借用数量</b></label>
                <div>
                  <el-input-number v-model="model.CNT" :step="1"></el-input-number>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>备注</b></label>
                <el-input type="text" v-model="model.DESC" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {},
      stock:{},
      cat:{
        ls:[],
        val:[]
      },
      ls_stock:[]
    }
  },
  methods:{
    init() {
      this.sta={show:true,loading:false}
      this.model = {};
      this.stock = {};
      this.cat.val = [];
      //if (this.cat.ls.length==0)
      this.getCat();
    },
    getCat(){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/MAT/MCatApi/GetTree",
        completed: function (its) {
          self.cat.ls=its.DATA;
        }
      })
    },
    getStock(v){
      let n=v.length;
      if (n>0) this.model.CAT_ID=v[n-1];
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/School/MAT/StockApi/GetList",
        data: {CAT_ID:this.model.CAT_ID},
        completed:function (its,n){
          self.model.ST_ID="";
          self.ls_stock=its;
          if (n===1) self.stockSel(its[0]);
        }
      })
    },
    stockChange(v){
      let self=this;
      this.ls_stock.forEach(it=>{
        if (it.ID===v) self.stockSel(it)
      });
    },
    stockSel(it){
      this.stock=it;
      this.model.ST_ID=it.ID
      this.model.ST_NAME=it.NAME;
      this.model.ST_UNIT=it.FUNIT;
      this.model.ST_PRICE=it.PRICE;
      this.model.CAT_NAME=it.CAT_NAME;
      this.model.CAT_ID=it.CAT_ID;
    },
    submit(){
      let self = this;
      this.model.AMT=this.model.ST_PRICE*this.model.CNT;
      if (this.model.ST_ID===undefined){
        this.whale.toast.err("请选择物品")
        return
      }
      self.sta.show=false;
      self.$emit("on-saved",this.model);
    }
  }
}
</script>
